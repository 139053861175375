import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  issuer: process.env.VUE_APP_OKTA_ISSUER || 'https://tenantinc.okta.com/',
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true // Enable PKCE for enhanced security
});
export default oktaAuth;
